<template>
  <div class="content">
    <div class="PageHeader" :class="{ 'PageHeader-border': !noBorder }">
      <div class="row align-items-center">
        <div class="col d-flex align-items-center">
          <div v-if="icon" class="mr-4">
            <i :class="`fa fa-2x fa-fw fa-${icon}`"></i>
          </div>
          <div class="flex-grow-1">
            <h1 :class="{ 'mb-1': subTitle, 'mb-0': !subTitle }">{{ title }}</h1>
            <p v-if="subTitle" class="text-muted font-w500 mb-0">{{ subTitle }}</p>
          </div>
        </div>
        <div v-if="rightTitle" class="col text-right">
          <h3 class="mb-0">{{ rightTitle }}</h3>
        </div>
        <div v-if="$slots['actions']" class="col text-right">
          <slot name="actions"></slot>
        </div>
        <div v-if="spinner" class="col text-right">
          <i class="fa fa-spinner-third fa-spin fa-3x text-info" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PageHeader',
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    rightTitle: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    spinner: {
      type: Boolean,
      required: false,
      default: false
    },
    noBorder: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
