<template>
  <div>
    <PageHeader title="Portfolio Management" />
    <div class="content">
      <div class="row justify-content-center">
        <div class="col-md-4">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h4 class="mb-0">Portfolios</h4>
            <router-link data-cy="portfolio-new" :to="{ name: 'portfolio-create' }" class="btn btn-primary btn-sm"
              ><i class="fa fa-plus mr-1"></i> New Portfolio</router-link
            >
          </div>

          <VerticalPills v-if="!loadingAction.list && assetGroups.length > 0" item-key="_id" :items="filteredAssetGroups">
            <template #item="{ item }">
              <a
                href="#"
                class="nav-link"
                :class="{ active: selectedAssetGroup && item._id === selectedAssetGroup._id }"
                @click.prevent="onClickAssetGroup(item)"
              >
                <div class="mb-1">{{ item.name }}</div>
                <!-- <div v-if="item.type === 'custom'" class="font-size-sm font-w600">Custom Portfolio ({{ item.assetIds?.length }} assets)</div>
                <div v-else-if="item.type === 'entity'" class="font-size-sm font-w600">Entity Portfolio ({{ item.entities?.length }} entities)</div>
                <div v-else-if="item.type === 'company'" class="font-size-sm font-w600">Company Portfolio ({{ item.company?.name }})</div> -->
              </a>
            </template>
          </VerticalPills>
          <Spinner v-else-if="loadingAction.list">Loading portfolios...</Spinner>
          <div v-else class="alert alert-warning">No portfolios found.</div>
        </div>
        <div class="col-md-8 bg-body-dark pt-4" data-cy="portfolio-table">
          <div v-if="selectedAssetGroup">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <div>
                <h3 class="mb-2">{{ selectedAssetGroup.name }}</h3>
                <h5 class="mb-2 text-capitalize">
                  {{ selectedAssetGroup.type }} Portfolio:
                  <div v-for="(entity, index) in selectedAssetGroup.entities" :key="index">
                    <span class="badge badge-info">
                      {{ entity.legalName }}
                    </span>
                  </div>
                  <span v-if="selectedAssetGroup.type === 'company'"> - {{ selectedAssetGroup.company.name }}</span>
                </h5>
              </div>
              <p>
                <span>
                  <router-link
                    data-cy="view-dashboard"
                    :to="{ name: 'portfolio-assets', params: { id: selectedAssetGroup._id } }"
                    class="btn btn-sm btn-primary mr-2"
                    ><i class="fa fa-tachometer mr-1"></i> View Dashboard</router-link
                  >
                  <router-link
                    v-if="canEditPortfolio"
                    :to="{ name: 'portfolio-edit', params: { id: selectedAssetGroup._id } }"
                    class="btn btn-sm btn-alt-info mr-2"
                    data-cy="edit-portfolio"
                    ><i class="fa fa-pencil mr-1"></i> Edit Portfolio</router-link
                  >
                  <button v-if="canEditPortfolio" data-cy="delete-portfolio" class="btn btn-sm btn-alt-danger mr-2" @click="onClickRemove">
                    <i class="fa fa-trash mr-1"></i> Delete Portfolio
                  </button>
                </span>
              </p>
            </div>
            <div v-if="selectedAssetGroup.type === 'custom'">
              <!-- Users -->
              <div class="d-flex justify-content-between align-items-center mb-4">
                <h4 class="mb-0">Users</h4>
              </div>
              <div class="block block-rounded mb-3 flex-fill">
                <div
                  v-if="selectedAssetGroup.userSub || (selectedAssetGroup.userSubs && selectedAssetGroup.userSubs.length > 0)"
                  class="block-content block-content-full font-size-sm"
                >
                  <p v-for="(userSub, index) in selectedAssetGroupUsers" :key="userSub" class="font-w700 mb-0">
                    {{ userSub | user(users) }}
                    <span v-if="index === 0" class="badge badge-primary">Creator</span>
                  </p>
                </div>
                <div v-else class="alert alert-warning">No users associated with this portfolio</div>
              </div>
            </div>

            <div>
              <!-- Assets -->
              <div class="d-flex justify-content-between align-items-center mb-4">
                <h4 class="mb-0">Assets</h4>
                <button
                  v-if="canEditPortfolio && selectedAssetGroup.type === 'custom'"
                  data-cy="add-asset"
                  class="btn btn-sm btn-alt-info"
                  @click.prevent="onClickAdd"
                >
                  <i class="fa fa-plus mr-1"></i> Add Asset
                </button>
              </div>
              <div v-if="!loadingAction.listAssets && assets.length > 0">
                <div v-for="asset in sortedAssets" :key="asset._id" class="block block-rounded mb-3 flex-fill">
                  <div class="block-content block-content-full font-size-sm">
                    <div class="d-flex justify-content-between align-items-top mb-4">
                      <div>
                        <p class="font-size-h4 font-w700 mb-0">
                          <i class="fa mr-1" :class="`${assetTypes.find(t => t.value === asset.assetType)?.icon}`"></i> {{ asset.siteName }}
                        </p>
                        <p class="mb-0">{{ niceAddress(asset.address) }}</p>
                      </div>
                      <div>
                        <router-link :to="{ name: 'asset-overview', params: { id: asset._id } }" class="btn btn-primary btn-sm mr-2"
                          ><i class="fa fa-search mr-1"></i> View</router-link
                        >
                        <button
                          v-if="canEditPortfolio && selectedAssetGroup.type === 'custom'"
                          class="btn btn-alt-info btn-sm mr-2"
                          @click.prevent="onClickMove(asset)"
                        >
                          <i class="fa fa-arrow-from-bottom mr-1"></i> Move
                        </button>
                        <button
                          v-if="canEditPortfolio && selectedAssetGroup.type === 'custom'"
                          class="btn btn-alt-danger btn-sm"
                          @click.prevent="onClickUnassign(asset)"
                        >
                          <i class="fa fa-minus mr-1"></i> Unassign
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Spinner v-else-if="loadingAction.listAssets">Loading assets...</Spinner>
              <div v-else class="alert alert-warning">No assets found.</div>
            </div>
          </div>
          <div v-else>
            <div class="alert alert-warning">
              No portfolio selected. Select one from the menu on the left, or create one by clicking the New Portfolio button.
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal :open="modals.move" title="Move Asset" prevent @close="modals.move = false" @submit="onConfirmMove">
        <p>Select a portfolio to move this asset to:</p>
        <FormGroup
          id="asset-move-list"
          v-model="moveAssetToGroupId"
          type="select"
          :options="assetGroups.map(a => ({ label: a.name, value: a._id }))"
        />
      </ConfirmModal>
      <ConfirmModal id="delete" :open="modals.remove" title="Delete Portfolio" @close="modals.remove = false" @submit="onConfirmRemove">
        <p>
          Please confirm you would like to delete the portfolio <strong>{{ modals.remove.name }}</strong>
        </p>
      </ConfirmModal>
      <ConfirmModal :open="modals.unassign" title="Remove asset from portfolio" @close="modals.unassign = false" @submit="onConfirmUnassign">
        <p>
          Please confirm you would like to remove the asset <strong>{{ modals.unassign.siteName }}</strong> from the portfolio
          <strong v-if="selectedAssetGroup">{{ selectedAssetGroup.name }}</strong
          >. The Asset will not be deleted.
        </p>
      </ConfirmModal>
      <ConfirmModal
        v-if="selectedAssetGroup"
        id="asset-search"
        :open="modals.add"
        title="Add Asset to Portfolio"
        prevent
        lg-size
        @close="handleClose"
        @submit="onConfirmAdd"
      >
        <LookaheadSearch
          v-if="!loading && modals.add"
          ref="lookaheadComponent"
          :key="lookaheadKey"
          :title="'Asset'"
          :loading="loadingAction.find"
          default-sort="name:1"
          :action="onSearchAssets"
          :search-on-load="true"
          :search-by-options="searchByAssetOptions"
          :select-search-by="(addAssetsToGroupId = [])"
          @select="e => onClickAsset(e)"
        >
          <template v-slot:result="slotProps">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <div>{{ slotProps.data.siteName }}</div>

                <div class="badge badge-primary text-capitalize mr-2">
                  {{ slotProps.data.address.streetAddress }}, {{ slotProps.data.address.postCode }}
                </div>

                <div>
                  <span v-if="slotProps.data.entity" class="badge badge-secondary text-capitalize mr-2">
                    {{ slotProps.data.entity.legalName }}
                  </span>
                </div>
              </div>
              <div class="custom-control custom-checkbox custom-control-inline">
                <input
                  :checked="addAssetsToGroupId.includes(slotProps.data._id)"
                  type="checkbox"
                  class="custom-control-input checkbox"
                  @click.stop="onClickAsset(slotProps['data'])"
                />
                <label class="custom-control-label" :for="slotProps.data._id"></label>
              </div>
            </div>
          </template>
        </LookaheadSearch>
        <div v-else class="d-flex justify-content-center">
          <Spinner v-if="loading">Updating {{ title }}..</Spinner>
        </div>
      </ConfirmModal>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { niceAddress } from '../../lib/helpers';
import ConfirmModal from '@/components/ConfirmModal';
import FormGroup from '@/components/FormGroup';
import PageHeader from '@/components/PageHeader';
import Spinner from '@/components/Spinner';
import LookaheadSearch from '@/components/LookaheadSearch';
import VerticalPills from '@/components/base/VerticalPills';

export default {
  name: 'PortfolioManagement',
  components: {
    ConfirmModal,
    FormGroup,
    PageHeader,
    Spinner,
    LookaheadSearch,
    VerticalPills
  },
  data() {
    return {
      modals: {
        add: false,
        move: false,
        unassign: false,
        remove: false
      },
      selectedAssetGroup: null,
      moveAssetToGroupId: null,
      addAssetsToGroupId: [],
      loading: false,
      lookaheadKey: 0,
      search: '',
      searchByAssetOptions: [
        { value: 'assets', display: 'Asset Name/Address' },
        { value: 'entities', display: 'Entity Name/Address' }
      ]
    };
  },
  computed: {
    ...mapGetters({
      assets: 'assetGroup/assets',
      assetGroups: 'assetGroup/assetGroups',
      assetTypes: 'util/assetTypes',
      loadingAction: 'assetGroup/loadingAction',
      users: 'company/users'
    }),
    sortedAssets() {
      const assets = [...this.assets];

      assets.sort((a, b) => a.siteName.localeCompare(b.siteName));

      return assets;
    },
    filteredAssetGroups() {
      return this.assetGroups.filter(a => (this.search ? (a.name || '').toLowerCase().includes(this.search.toLowerCase()) : true));
    },
    isOwnAssetGroup() {
      return this.selectedAssetGroup && this.selectedAssetGroup.userSub === this.$auth.user.sub;
    },
    canEditPortfolio() {
      if (!this.selectedAssetGroup) return false;

      // Disallow entity users - unless its their own custom portfolio
      if (this.selectedAssetGroup.type === 'custom' && (this.$permissions.isCompanyUser() || this.isOwnAssetGroup)) return true;

      return this.$permissions.isCompanyUser();
    },
    selectedAssetGroupUsers() {
      const nonCreatorUserSubs = this.selectedAssetGroup.userSubs.filter(u => u !== this.selectedAssetGroup.userSub);
      return [this.selectedAssetGroup.userSub, ...nonCreatorUserSubs];
    }
  },
  async mounted() {
    await this.listAssetGroups({ data: { params: { $sort: 'name:1', $populate: 'entities' } } });
    this.selectedAssetGroup = this.assetGroups[0];
    this.listAssetTypes();

    if (this.selectedAssetGroup?._id) {
      this.listAssets({ id: this.selectedAssetGroup._id, data: { params: { $select: 'siteName,address,assetType' } } });
      this.listUsers({ id: this.selectedAssetGroup.companyId });
    }
  },
  methods: {
    niceAddress,
    ...mapActions({
      listAssetGroups: 'assetGroup/list',
      removeAssetGroup: 'assetGroup/remove',
      listAssets: 'assetGroup/listAssets',
      listAssetTypes: 'util/listAssetTypes',
      addAsset: 'assetGroup/addAsset',
      addAssets: 'assetGroup/addAssets',
      removeAsset: 'assetGroup/removeAsset',
      searchAssets: 'assetGroup/searchAssets',
      listUsers: 'company/listUsers'
    }),
    ...mapMutations({
      resetSearchAssets: 'assetGroup/RESET_SEARCH_ASSETS_STATE'
    }),
    async refresh() {
      await this.listAssetGroups({ data: { params: { $sort: 'name:1', $populate: 'entities' } } });
      this.listAssetTypes();
      if (!this.selectedAssetGroup) this.selectedAssetGroup = this.assetGroups[0];
      this.listAssets({ id: this.selectedAssetGroup._id });
    },
    onClickAssetGroup(assetGroup) {
      this.listAssets({ id: assetGroup._id });
      this.selectedAssetGroup = assetGroup;
    },
    onClickMove(asset) {
      this.modals.move = asset;
    },
    onClickUnassign(asset) {
      this.modals.unassign = asset;
    },
    onClickAdd() {
      this.modals.add = true;
    },
    async onConfirmMove() {
      if (this.selectedAssetGroup) {
        await this.removeAsset({ id: this.selectedAssetGroup._id, assetId: this.modals.move._id });
      }

      await this.addAsset({ id: this.moveAssetToGroupId, assetId: this.modals.move._id });

      this.listAssets();

      this.modals.move = false;
      this.moveAssetToGroupId = null;
      this.refresh();
      this.$toasted.success('Moved asset successfully');
    },
    async onConfirmUnassign() {
      if (this.selectedAssetGroup) {
        await this.removeAsset({ id: this.selectedAssetGroup._id, assetId: this.modals.unassign._id });
      }

      this.listAssets();

      this.modals.unassign = false;
      this.refresh();
      this.$toasted.success('Remove asset from portfolio successfully');
    },
    onClickRemove() {
      this.modals.remove = { ...this.selectedAssetGroup };
    },
    async onConfirmRemove() {
      const removeResponse = await this.removeAssetGroup({ id: this.modals.remove._id });

      this.modals.remove = false;
      if (removeResponse._id) {
        this.$toasted.success('Portfolio removed successfully');
        this.selectedAssetGroup = null;
      } else {
        this.$toasted.error('There was an error removing this portfolio');
      }

      this.refresh();
    },
    async onSearchAssets(query, params) {
      params.query = query;
      return await this.searchAssets({ params, id: this.selectedAssetGroup._id });
    },
    async onConfirmAdd() {
      if (this.addAssetsToGroupId.length === 0) {
        this.$toasted.error('Please select an asset to add');
      } else {
        this.loading = true;
        await this.addAssets({ id: this.selectedAssetGroup._id, assetIds: this.addAssetsToGroupId });
        this.addAssetsToGroupId = [];
        this.refresh();
        this.loading = false;
        this.modals.add = false;
        this.$toasted.success('Added asset to portfolio successfully');
      }
    },
    async onClickAsset(asset) {
      const checked = this.addAssetsToGroupId.includes(asset.id);
      if (checked) {
        this.addAssetToGroupId = this.addAssetToGroupId.filter(id => id !== asset.Id);
      } else {
        this.addAssetsToGroupId.push(asset.id);
      }
    },
    handleClose() {
      this.modals.add = false;
      this.addAssetsToGroupId = [];
      this.lookaheadKey += 1;
      this.resetSearchAssets();
    }
  }
};
</script>
<style scoped>
.checkbox {
  z-index: 1000;
}
</style>
