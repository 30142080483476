<template>
  <div v-if="groups" class="VerticalPills VerticalPills--grouped" :class="{ 'VerticalPills--alt': alt }">
    <div v-for="group in groups" :key="group.title" class="mb-4" :class="{ 'bg-lightest rounded-md p-3': alt }">
      <div v-if="!group.hideTitle" class="d-flex align-items-center VerticalPills-group" @click.prevent="toggle(group)">
        <div class="text-uppercase text-muted font-w600 flex-grow-1 font-size-sm">
          <slot name="title" :title="group.title">{{ group.title }}</slot>
        </div>
        <i v-if="show[group.title]" class="fa fa-fw fa-angle-down text-muted"></i>
        <i v-else class="fa fa-fw fa-angle-up text-muted"></i>
      </div>
      <div v-if="show[group.title]" :class="{ 'mt-2': !group.hideTitle }">
        <div
          v-for="item in items[group.title]"
          :key="item[itemKey]"
          class="VerticalPills-item"
          :class="{ 'VerticalPills-item--child': item.isSubItem }"
        >
          <slot name="item" :item="item" :group="group">
            <div class="mb-1">{{ item.name }}</div>
          </slot>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="VerticalPills" :class="{ 'VerticalPills--alt': alt }">
    <div :class="{ 'bg-lightest rounded-md p-3': alt }">
      <div class="d-flex align-items-center">
        <div v-if="title" class="text-uppercase text-muted font-w600 flex-grow-1 mb-2 font-size-sm">
          <slot name="title" :title="title">{{ title }}</slot>
        </div>
      </div>
      <div v-for="item in items" :key="itemKey ? item[itemKey] : item" class="VerticalPills-item">
        <slot name="item" :item="item">{{ item }}</slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VerticalPills',
  props: {
    items: {
      type: [Array, Object],
      required: true
    },
    itemKey: {
      type: String,
      required: false,
      default: ''
    },
    groups: {
      type: Array,
      required: false,
      default: null
    },
    groupBy: {
      type: String,
      required: false,
      default: 'name'
    },
    sortBy: {
      type: String,
      required: false,
      default: 'name'
    },
    alt: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      showOverride: {},
      hideOverride: {}
    };
  },
  computed: {
    show() {
      return (this.groups || []).reduce((acc, group) => {
        acc[group.title] = (group.show && !this.hideOverride[group.title]) || this.showOverride[group.title];
        return acc;
      }, {});
    }
  },
  methods: {
    toggle(group) {
      const currentShow = this.show[group.title];

      this.$set(this.showOverride, group.title, !currentShow);
      this.$set(this.hideOverride, group.title, currentShow);
    }
  }
};
</script>
<style lang="scss">
@import '~@/assets/_scss/custom/variables';

.VerticalPills {
  & > div {
    cursor: pointer;
  }

  &-group {
    padding-left: 10px;
  }

  &-item {
    position: relative;
  }

  &-item .nav-link {
    padding: 6px 6px 6px 10px;
    border-radius: 10px;
  }

  &-item .nav-link:hover {
    color: inherit;
    background-color: $gray-hover;
  }

  &--alt &-item .nav-link:hover {
    background-color: darken($gray-hover, 3%);
  }

  &-item .nav-link.active {
    background-color: $gray-hover;
    border-radius: 10px;
  }

  &-item .nav-link.active::after {
    background: $primary;
    border-radius: 6px;
    content: '';
    height: 70%;
    left: -5px;
    position: absolute;
    width: 4px;
    top: calc(15%);
    padding: 5px 0;
  }

  &-item:not(&-item--child) + &-item--child {
    margin-top: 5px;
  }

  &-item--child {
    border-radius: 10px;
    font-size: 95%;
    margin-left: 24px;
    margin-bottom: 5px;
  }

  &-item--child .nav-link::after {
    position: absolute;
    background: #ddd;
    border-radius: 6px;
    content: '';
    height: 70%;
    left: -7px;
    width: 2px;
    top: calc(15%);
    padding: 5px 0;
  }

  &-item--child .nav-link.active::after {
    position: absolute;
    background: $primary;
    border-radius: 6px;
    content: '';
    height: 70%;
    left: -7px;
    width: 2px;
    top: calc(15%);
    padding: 5px 0;
  }

  &-item--disabled {
    opacity: 0.4;
  }
}
</style>
