<template>
  <div class="push">
    <div class="form-group">
      <label>{{ title }} Search</label>
      <div class="input-group">
        <input data-cy="lookahead-search" class="form-control" :placeholder="searchText" @input="onInput" />
        <div v-if="searchIcon" class="input-group-append">
          <span class="input-group-text border-0">
            <i class="fa fa-fw fa-search"></i>
          </span>
        </div>
        <div v-if="searchByOptions.length > 0" class="input-group-append mr-7">
          <select v-model="searchBy" class="form-control form-control-alt" @change="onSearchBy">
            <option v-for="(search, index) in searchByOptions" :key="index" :value="search.value">{{ search.display }}</option>
          </select>
        </div>
        <div v-if="searchByOptions.length > 0" class="mr-3 white-space d-flex align-items-center"><strong>Sort By</strong></div>
        <div class="input-group-append">
          <select v-if="!noDropdown" v-model="sort" class="form-control form-control-alt" placeholder="Sort by..." @input="onSort">
            <option value="name:1">Name (A-Z)</option>
            <option value="name:-1">Name (Z-A)</option>
            <option value="createdAt:-1">Created Date (Newest)</option>
            <option value="createdAt:1">Created Date (Oldest)</option>
          </select>
        </div>
      </div>
    </div>

    <span v-if="!noDropdown && results.total > 0" class="d-flex justify-content-between">
      <label>Results (Page {{ page }})</label>
      <label>Showing {{ results.skip + 1 }} - {{ results.skip + results.data.length }} out of {{ results.total }}</label>
    </span>

    <div v-if="!loading && results.total > 0">
      <div class="lookahead-results-overflow">
        <div class="list-group">
          <a
            v-for="result in results.data"
            :key="result._id"
            class="list-group-item list-group-item-action"
            href="#"
            @click.prevent.stop="onSelect(result)"
          >
            <slot name="result" :data="result"></slot>
          </a>
        </div>
      </div>

      <ul v-if="results.total > results.data.length" class="pagination mt-3">
        <li class="page-item" :class="{ disabled: page === 1 }">
          <button class="page-link" @click="onClickBack">
            <span aria-hidden="true">
              <i class="fa fa-angle-left"></i>
            </span>
            <span class="sr-only">Previous</span>
          </button>
        </li>
        <li class="page-item" :class="{ disabled: page >= results.total / limit }">
          <button class="page-link" @click="onClickNext">
            <span aria-hidden="true">
              <i class="fa fa-angle-right"></i>
            </span>
            <span class="sr-only">Next</span>
          </button>
        </li>
      </ul>
    </div>
    <div v-else-if="!loading && results.total === 0" class="alert alert-warning">No results found.</div>
    <Spinner v-else class="mb-3">Searching...</Spinner>
  </div>
</template>
<script>
import { debounce } from 'lodash';
import Spinner from './Spinner';

export default {
  name: 'LookaheadSearch',
  components: {
    Spinner
  },
  props: {
    action: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    noDropdown: {
      type: Boolean,
      required: false,
      default: false
    },
    searchIcon: {
      type: Boolean,
      default: false
    },
    searchOnLoad: {
      type: Boolean,
      default: false
    },
    defaultSort: {
      type: String,
      required: false,
      default: 'createdAt:-1'
    },
    searchByOptions: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      results: {
        data: [],
        total: 0,
        limit: 10,
        skip: 0
      },
      page: 1,
      limit: 10,
      query: '',
      sort: 'createdAt:-1',
      searchBy: '',
      loading: false
    };
  },
  computed: {
    searchText() {
      return `Search for an ${this.title}...`;
    }
  },
  mounted() {
    this.sort = this.defaultSort;
    if (this.searchOnLoad) this.refresh();
    if (this.searchByOptions.length > 0) this.searchBy = this.searchByOptions[0].value;
  },
  methods: {
    onInput: debounce(async function (e) {
      this.hasSearched = true;
      this.$emit('search', e.target.value);
      this.query = e.target.value;
      this.refresh();
    }, 500),
    onSort(e) {
      this.sort = e.target.value;
      this.refresh();
    },
    onSelect(result) {
      this.$emit('select', result);
    },
    onSearchBy() {
      this.$emit('selectSearchBy');
      this.refresh();
    },
    async onClickNext() {
      if (this.page < this.results.total / this.limit) this.page += 1;
      this.refresh();
    },
    async onClickBack() {
      if (this.page > 1) this.page -= 1;
      this.refresh();
    },
    async refresh() {
      this.loading = true;

      const results = await this.action(this.query, {
        $skip: (this.page - 1) * this.limit,
        $limit: this.limit,
        $sort: this.sort,
        $searchBy: this.searchBy
      });

      this.$set(this, 'results', results);

      this.loading = false;
    },
    clear() {
      this.page = 1;
      this.refresh();
    }
  }
};
</script>
