var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeader', {
    attrs: {
      "title": "Portfolio Management"
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-3"
  }, [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v("Portfolios")]), _c('router-link', {
    staticClass: "btn btn-primary btn-sm",
    attrs: {
      "data-cy": "portfolio-new",
      "to": {
        name: 'portfolio-create'
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus mr-1"
  }), _vm._v(" New Portfolio")])], 1), !_vm.loadingAction.list && _vm.assetGroups.length > 0 ? _c('VerticalPills', {
    attrs: {
      "item-key": "_id",
      "items": _vm.filteredAssetGroups
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('a', {
          staticClass: "nav-link",
          class: {
            active: _vm.selectedAssetGroup && item._id === _vm.selectedAssetGroup._id
          },
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.onClickAssetGroup(item);
            }
          }
        }, [_c('div', {
          staticClass: "mb-1"
        }, [_vm._v(_vm._s(item.name))])])];
      }
    }], null, false, 997890692)
  }) : _vm.loadingAction.list ? _c('Spinner', [_vm._v("Loading portfolios...")]) : _c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v("No portfolios found.")])], 1), _c('div', {
    staticClass: "col-md-8 bg-body-dark pt-4",
    attrs: {
      "data-cy": "portfolio-table"
    }
  }, [_vm.selectedAssetGroup ? _c('div', [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-4"
  }, [_c('div', [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v(_vm._s(_vm.selectedAssetGroup.name))]), _c('h5', {
    staticClass: "mb-2 text-capitalize"
  }, [_vm._v(" " + _vm._s(_vm.selectedAssetGroup.type) + " Portfolio: "), _vm._l(_vm.selectedAssetGroup.entities, function (entity, index) {
    return _c('div', {
      key: index
    }, [_c('span', {
      staticClass: "badge badge-info"
    }, [_vm._v(" " + _vm._s(entity.legalName) + " ")])]);
  }), _vm.selectedAssetGroup.type === 'company' ? _c('span', [_vm._v(" - " + _vm._s(_vm.selectedAssetGroup.company.name))]) : _vm._e()], 2)]), _c('p', [_c('span', [_c('router-link', {
    staticClass: "btn btn-sm btn-primary mr-2",
    attrs: {
      "data-cy": "view-dashboard",
      "to": {
        name: 'portfolio-assets',
        params: {
          id: _vm.selectedAssetGroup._id
        }
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-tachometer mr-1"
  }), _vm._v(" View Dashboard")]), _vm.canEditPortfolio ? _c('router-link', {
    staticClass: "btn btn-sm btn-alt-info mr-2",
    attrs: {
      "to": {
        name: 'portfolio-edit',
        params: {
          id: _vm.selectedAssetGroup._id
        }
      },
      "data-cy": "edit-portfolio"
    }
  }, [_c('i', {
    staticClass: "fa fa-pencil mr-1"
  }), _vm._v(" Edit Portfolio")]) : _vm._e(), _vm.canEditPortfolio ? _c('button', {
    staticClass: "btn btn-sm btn-alt-danger mr-2",
    attrs: {
      "data-cy": "delete-portfolio"
    },
    on: {
      "click": _vm.onClickRemove
    }
  }, [_c('i', {
    staticClass: "fa fa-trash mr-1"
  }), _vm._v(" Delete Portfolio ")]) : _vm._e()], 1)])]), _vm.selectedAssetGroup.type === 'custom' ? _c('div', [_vm._m(0), _c('div', {
    staticClass: "block block-rounded mb-3 flex-fill"
  }, [_vm.selectedAssetGroup.userSub || _vm.selectedAssetGroup.userSubs && _vm.selectedAssetGroup.userSubs.length > 0 ? _c('div', {
    staticClass: "block-content block-content-full font-size-sm"
  }, _vm._l(_vm.selectedAssetGroupUsers, function (userSub, index) {
    return _c('p', {
      key: userSub,
      staticClass: "font-w700 mb-0"
    }, [_vm._v(" " + _vm._s(_vm._f("user")(userSub, _vm.users)) + " "), index === 0 ? _c('span', {
      staticClass: "badge badge-primary"
    }, [_vm._v("Creator")]) : _vm._e()]);
  }), 0) : _c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v("No users associated with this portfolio")])])]) : _vm._e(), _c('div', [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-4"
  }, [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v("Assets")]), _vm.canEditPortfolio && _vm.selectedAssetGroup.type === 'custom' ? _c('button', {
    staticClass: "btn btn-sm btn-alt-info",
    attrs: {
      "data-cy": "add-asset"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClickAdd($event);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus mr-1"
  }), _vm._v(" Add Asset ")]) : _vm._e()]), !_vm.loadingAction.listAssets && _vm.assets.length > 0 ? _c('div', _vm._l(_vm.sortedAssets, function (asset) {
    var _vm$assetTypes$find;

    return _c('div', {
      key: asset._id,
      staticClass: "block block-rounded mb-3 flex-fill"
    }, [_c('div', {
      staticClass: "block-content block-content-full font-size-sm"
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-top mb-4"
    }, [_c('div', [_c('p', {
      staticClass: "font-size-h4 font-w700 mb-0"
    }, [_c('i', {
      staticClass: "fa mr-1",
      class: "".concat((_vm$assetTypes$find = _vm.assetTypes.find(function (t) {
        return t.value === asset.assetType;
      })) === null || _vm$assetTypes$find === void 0 ? void 0 : _vm$assetTypes$find.icon)
    }), _vm._v(" " + _vm._s(asset.siteName) + " ")]), _c('p', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(_vm.niceAddress(asset.address)))])]), _c('div', [_c('router-link', {
      staticClass: "btn btn-primary btn-sm mr-2",
      attrs: {
        "to": {
          name: 'asset-overview',
          params: {
            id: asset._id
          }
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-search mr-1"
    }), _vm._v(" View")]), _vm.canEditPortfolio && _vm.selectedAssetGroup.type === 'custom' ? _c('button', {
      staticClass: "btn btn-alt-info btn-sm mr-2",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onClickMove(asset);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-arrow-from-bottom mr-1"
    }), _vm._v(" Move ")]) : _vm._e(), _vm.canEditPortfolio && _vm.selectedAssetGroup.type === 'custom' ? _c('button', {
      staticClass: "btn btn-alt-danger btn-sm",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onClickUnassign(asset);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-minus mr-1"
    }), _vm._v(" Unassign ")]) : _vm._e()], 1)])])]);
  }), 0) : _vm.loadingAction.listAssets ? _c('Spinner', [_vm._v("Loading assets...")]) : _c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v("No assets found.")])], 1)]) : _c('div', [_c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v(" No portfolio selected. Select one from the menu on the left, or create one by clicking the New Portfolio button. ")])])])]), _c('ConfirmModal', {
    attrs: {
      "open": _vm.modals.move,
      "title": "Move Asset",
      "prevent": ""
    },
    on: {
      "close": function close($event) {
        _vm.modals.move = false;
      },
      "submit": _vm.onConfirmMove
    }
  }, [_c('p', [_vm._v("Select a portfolio to move this asset to:")]), _c('FormGroup', {
    attrs: {
      "id": "asset-move-list",
      "type": "select",
      "options": _vm.assetGroups.map(function (a) {
        return {
          label: a.name,
          value: a._id
        };
      })
    },
    model: {
      value: _vm.moveAssetToGroupId,
      callback: function callback($$v) {
        _vm.moveAssetToGroupId = $$v;
      },
      expression: "moveAssetToGroupId"
    }
  })], 1), _c('ConfirmModal', {
    attrs: {
      "id": "delete",
      "open": _vm.modals.remove,
      "title": "Delete Portfolio"
    },
    on: {
      "close": function close($event) {
        _vm.modals.remove = false;
      },
      "submit": _vm.onConfirmRemove
    }
  }, [_c('p', [_vm._v(" Please confirm you would like to delete the portfolio "), _c('strong', [_vm._v(_vm._s(_vm.modals.remove.name))])])]), _c('ConfirmModal', {
    attrs: {
      "open": _vm.modals.unassign,
      "title": "Remove asset from portfolio"
    },
    on: {
      "close": function close($event) {
        _vm.modals.unassign = false;
      },
      "submit": _vm.onConfirmUnassign
    }
  }, [_c('p', [_vm._v(" Please confirm you would like to remove the asset "), _c('strong', [_vm._v(_vm._s(_vm.modals.unassign.siteName))]), _vm._v(" from the portfolio "), _vm.selectedAssetGroup ? _c('strong', [_vm._v(_vm._s(_vm.selectedAssetGroup.name))]) : _vm._e(), _vm._v(". The Asset will not be deleted. ")])]), _vm.selectedAssetGroup ? _c('ConfirmModal', {
    attrs: {
      "id": "asset-search",
      "open": _vm.modals.add,
      "title": "Add Asset to Portfolio",
      "prevent": "",
      "lg-size": ""
    },
    on: {
      "close": _vm.handleClose,
      "submit": _vm.onConfirmAdd
    }
  }, [!_vm.loading && _vm.modals.add ? _c('LookaheadSearch', {
    key: _vm.lookaheadKey,
    ref: "lookaheadComponent",
    attrs: {
      "title": 'Asset',
      "loading": _vm.loadingAction.find,
      "default-sort": "name:1",
      "action": _vm.onSearchAssets,
      "search-on-load": true,
      "search-by-options": _vm.searchByAssetOptions,
      "select-search-by": _vm.addAssetsToGroupId = []
    },
    on: {
      "select": function select(e) {
        return _vm.onClickAsset(e);
      }
    },
    scopedSlots: _vm._u([{
      key: "result",
      fn: function fn(slotProps) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center"
        }, [_c('div', [_c('div', [_vm._v(_vm._s(slotProps.data.siteName))]), _c('div', {
          staticClass: "badge badge-primary text-capitalize mr-2"
        }, [_vm._v(" " + _vm._s(slotProps.data.address.streetAddress) + ", " + _vm._s(slotProps.data.address.postCode) + " ")]), _c('div', [slotProps.data.entity ? _c('span', {
          staticClass: "badge badge-secondary text-capitalize mr-2"
        }, [_vm._v(" " + _vm._s(slotProps.data.entity.legalName) + " ")]) : _vm._e()])]), _c('div', {
          staticClass: "custom-control custom-checkbox custom-control-inline"
        }, [_c('input', {
          staticClass: "custom-control-input checkbox",
          attrs: {
            "type": "checkbox"
          },
          domProps: {
            "checked": _vm.addAssetsToGroupId.includes(slotProps.data._id)
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.onClickAsset(slotProps['data']);
            }
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": slotProps.data._id
          }
        })])])];
      }
    }], null, false, 970279335)
  }) : _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_vm.loading ? _c('Spinner', [_vm._v("Updating " + _vm._s(_vm.title) + "..")]) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-4"
  }, [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v("Users")])]);
}]

export { render, staticRenderFns }