var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.groups ? _c('div', {
    staticClass: "VerticalPills VerticalPills--grouped",
    class: {
      'VerticalPills--alt': _vm.alt
    }
  }, _vm._l(_vm.groups, function (group) {
    return _c('div', {
      key: group.title,
      staticClass: "mb-4",
      class: {
        'bg-lightest rounded-md p-3': _vm.alt
      }
    }, [!group.hideTitle ? _c('div', {
      staticClass: "d-flex align-items-center VerticalPills-group",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.toggle(group);
        }
      }
    }, [_c('div', {
      staticClass: "text-uppercase text-muted font-w600 flex-grow-1 font-size-sm"
    }, [_vm._t("title", [_vm._v(_vm._s(group.title))], {
      "title": group.title
    })], 2), _vm.show[group.title] ? _c('i', {
      staticClass: "fa fa-fw fa-angle-down text-muted"
    }) : _c('i', {
      staticClass: "fa fa-fw fa-angle-up text-muted"
    })]) : _vm._e(), _vm.show[group.title] ? _c('div', {
      class: {
        'mt-2': !group.hideTitle
      }
    }, _vm._l(_vm.items[group.title], function (item) {
      return _c('div', {
        key: item[_vm.itemKey],
        staticClass: "VerticalPills-item",
        class: {
          'VerticalPills-item--child': item.isSubItem
        }
      }, [_vm._t("item", [_c('div', {
        staticClass: "mb-1"
      }, [_vm._v(_vm._s(item.name))])], {
        "item": item,
        "group": group
      })], 2);
    }), 0) : _vm._e()]);
  }), 0) : _c('div', {
    staticClass: "VerticalPills",
    class: {
      'VerticalPills--alt': _vm.alt
    }
  }, [_c('div', {
    class: {
      'bg-lightest rounded-md p-3': _vm.alt
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.title ? _c('div', {
    staticClass: "text-uppercase text-muted font-w600 flex-grow-1 mb-2 font-size-sm"
  }, [_vm._t("title", [_vm._v(_vm._s(_vm.title))], {
    "title": _vm.title
  })], 2) : _vm._e()]), _vm._l(_vm.items, function (item) {
    return _c('div', {
      key: _vm.itemKey ? item[_vm.itemKey] : item,
      staticClass: "VerticalPills-item"
    }, [_vm._t("item", [_vm._v(_vm._s(item))], {
      "item": item
    })], 2);
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }